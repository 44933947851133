import React from 'react'
import './Banner.css'
import logo from '../../assets/snscript.svg'



const Banner = () => {
    return (
        <>
            <div className='banner'>
                <div className='left'>
                    <img src={logo} alt='logo' className='bannerLogo' />
                </div>
                <div className='right'>
                    <div className='footerContainer'>
                        <h4>IGNITE PROGRAM</h4>
                        <span>
                            The IGNITE Data Protection Map provides an overview of data protection laws across African countries. It's a valuable resource for understanding the
                            legal landscape and compliance requirements across African
                        </span> 
                    </div>
                    
                </div>
                <br />
                
            </div>
            <div className='tiny-footer'>
                <div className='footerText'>
                    <ul>
                        <li>Copyright © 2024 Scratch & Script.</li>
                    </ul>
                </div>
                <div className='footerLinks'>
                    <ul>
                        <li>
                            <a href="https://www.scratchandscript.com/terms_condition" title="Terms &amp; Condition">Terms &amp; Condition</a>
                        </li>
                        <li>
                            <a href="https://www.scratchandscript.com/privacy_policy" title="Privacy Policy">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
        
    )
}

export default Banner