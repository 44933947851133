import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import './About.css'
import Partners from '../../Components/Partners/Partners'
import Banner from '../../Components/Banner/Banner'
import photo1 from '../../assets/Ann Noel Akoth.jpeg'
import photo2 from '../../assets/georginagiathi.jpeg'
import photo3 from '../../assets/elsiedulo.jpeg'
import photo4 from '../../assets/adwets.jpeg'
import photo5 from '../../assets/isabelkirabo.jpeg'
import photo6 from '../../assets/adegbajuemmanuel.jpeg'
import photo7 from '../../assets/gideonmutai.jpeg'
import photo8 from '../../assets/joshuaokemwa.jpeg'
import photo9 from '../../assets/gusongoiryebrian.jpeg'
import photo10 from '../../assets/naomiemma.jpeg'



const About = () => {
    return (
        <div>
            <Navbar />
            <div className='title'>
                <h3>About</h3>
            </div>
            <div className='about'>
                <div className='about_navigation'>
                    <Sidebar />
                </div>
                <div className='about_content'>
                    <div className='about_text'>
                        <div className='img-container'>
                            <h4>What to Expect</h4>
                            <p>
                            Welcome to the inaugural edition of the IGNITE Program's Data Protection Laws of Africa Map. 
                            We're thrilled to present this comprehensive guide, researched and compiled by our dedicated 
                            team of over 100 IGNITE graduates across five countries. Covering all 54 nations in Africa, 
                            this platform offers invaluable insights into the diverse landscape of privacy and data protection 
                            laws across the continent.
                            </p>

                            <p>
                            Looking ahead, 2024 holds the promise of being a landmark year in the evolution of data protection and 
                            privacy law. With new regulations continually emerging, we anticipate further developments that will shape the digital landscape of Africa and beyond.
                            </p>

                        </div>
                    </div>
                    <h3>IGNITE Program Researchers</h3>
                    <div className='about_researchers'>
                        <div className='body'>
                            
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo1} alt='person1' />
                                </div>

                                <div className='name_country'>
                                    <h4>Ann Noel Akoth</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo5} alt='person5' />
                                </div>
                                <div className='name_country'>
                                    <h4>Isabel Kirabo</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo2} alt='person2' />
                                </div>
                                <div className='name_country'>
                                    <h4>Georgina Giathi</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo3} alt='person3' />
                                </div>
                                <div className='name_country'>
                                    <h4>Elsie Dulo</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo4} alt='person1' />
                                </div>
                                <div className='name_country'>
                                    <h4>Esther Adwets</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>
                            </div>
                        </div>

                        <div className='body'>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo6} alt='person6' />
                                </div>
                                <div className='name_country'>
                                    {/* <h4>Adegbaju Adeola Emmanuel</h4> */}
                                    <h4>Adegbaju Emmanuel</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo7} alt='person7' />
                                </div>
                                <div className='name_country'>
                                    <h4>Gideon Mutai</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>

                            </div>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo8} alt='person7' />
                                </div>
                                <div className='name_country'>
                                    {/* <h4>Joshua Ayieko Okemwa</h4> */}
                                    <h4>Joshua Okemwa</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>

                            </div>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo9} alt='person8' />
                                </div>
                                <div className='name_country'>
                                    <h4>Gusongoirye Brian</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>

                            </div>
                            <div className='card'>
                                <div className='img-container'>
                                    <img className='card_img' src={photo10} alt='person8' />
                                </div>
                                <div className='name_country'>
                                    {/* <h4>Naomi Emma Ekwealor</h4> */}
                                    <h4>Naomi Ekwealor</h4>
                                    <p>i|DPO</p>
                                    <p>Graduate IGNITE Cohort 1</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <Partners />
                <Banner />

            </div>
            
        </div>
    )
}
export default About