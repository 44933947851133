import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'

const Updates = () => {
    return (
        <div>
            <Navbar />
            <div>
                <h3>Updates</h3>
            </div>
        </div>
    )
}

export default Updates