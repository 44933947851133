import React, { useEffect, useState } from "react";
import './TextInputs.css';

export function TextInputs({elemType, elemName, elemId, elemMin, elemMax, defaultValue , elemDisabled}) {
    const [inputText, setInputText] = useState(defaultValue ? defaultValue : "");

    useEffect(() => {
        setInputText(defaultValue)
    }, [defaultValue])

    const handleChange = (e) => {
        setInputText(e.target.value)
    }

    return (
        <>
            <div className="inputDiv">
                <input className="textInputs" type={elemType} name={elemName} id={elemId} min={elemMin} max={elemMax}
                onChange={handleChange} value={inputText} disabled={elemDisabled}/>
            </div>
        </>
    )
}