import { useState } from "react";
import { CountryForm } from "../../Forms/CountryForm/CountryForm";
import Navbar from "../Navbar/Navbar";
import CarouselPage from "../Carousel/Carousel";


export function CountrySetup() {
    const [country, setCountry] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [countryId, setCountryId] = useState('');

    return (
        <>
            <Navbar />
            <CarouselPage />
            <CountryForm />
        
        </>
    )
}