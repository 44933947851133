import React, { Component, useEffect, useState } from "react";
import './ExceptionComponent.css';
import ExceptionService, {clearServiceMessages, displayMessage } from "../../ExceptionService";
import 'react-toastify/dist/ReactToastify.css';
import { Bounce, ToastContainer, toast } from "react-toastify";

class ExceptionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: {}
        }
    }

    componentDidMount() {
        this.updateState();
    }

    updateState = () => {
        
        setInterval(() =>{
            const msg = displayMessage();
            this.setState({
                messages: msg
            });
        }, 1000)
        
    }

    componentDidUpdate(prevState) {
    toast(this.state.messages.msgContent, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

    }
    // useEffect(() => {
    //     setMessages(displayMessage());
    //     setInterval(() => {
            
        
    //         toast('Welcome', {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //             transition: Bounce,
    //             });
            
    //     }, 1000);
        

        
        

    // }, [messages]);
    // const clearMessages = () => {
    //     if (messages.length !== 0) {
    //             // setMessages([messages.pop(), ...messages]);
    //             const clearMsg = setTimeout(() => {
    //             }, 1000);
                
    //             // setMessages([]);
    //     }

    // }
    render() { return(
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Bounce}
                />
            </div>
    )}
}

export default ExceptionComponent