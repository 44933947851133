import React, { useEffect, useRef } from "react";
import './CountryForm.css';
import { Label } from "../../Components/FormElements/Label/Label";
import { TextInputs } from "../../Components/FormElements/Inputs/TextInputs/TextInputs";
import { ButtonInput } from "../../Components/FormElements/Inputs/ButtonInputs/ButtonInputs";
import {DeleteCountry, GetCountries, PostCountry} from '../../services/CountryServices';
import { useState } from "react";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { Table } from "../../Components/Table/Table";


export function CountryForm() {
    const [pageData, setPageData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [countrySavedData, setCountryData] = useState([]);
    const [countryInfoData, setCountryInfoData] = useState([]);

    // values for setting defaults on form or edit
    const [countryCode, setCountryCode] = useState("");
    const [countryName, setCountryName] = useState("");
    const [callingCode, setCallingCode] = useState(0);
    const [officialLanguage, setOfficialLanguage] = useState("");
    const [capital, setCapital] = useState("");
    const [currency, setCurrency] = useState("");
    const [flag, setCountryFlag] = useState("");
    const [law, setLaw] = useState("");
    const [authority, setAuthority] = useState("");
    const [enforcement, setEnforcement] = useState("");
    const [penalties, setPenalties] = useState("");
    const [internetUsers, setInternetUsers] = useState(0);
    const [population, setPopulation] = useState(0);
    const [url, setUrl] = useState("/category");

    const [editData, editCountry] = useState(false);
    const [editId, editPrimaryId] = useState(0);

    const form = useRef(null);

    useEffect(() => {
        GetCountryData();
    }, []);

    let tableHeader = [
        "Country Code", 
        "Country Name",
        "Calling Code",
        "Official Language",
        "Capital",
        "Currency",
        "Country Flag",
        "Country Law",
        "Country Authority",
        "Country Enforcement",
        "Country Penalties",
        "Internet Users",
        "Population",
        "Url",
        "Actions"
    ]

    let countryData = {
        id: 0,
        countryCode: " ",
        countryName: " ",
        callingCode: 0,
        officialLanguage: " ",
        capital: " ",
        currency: " ",
        countryFlag: " ",
        internetUsers: 0,
        population: 0
    }

    const countrySubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(form.current);
        let finalData;

        if (editData) {
            countryData.id = editId;
        }
        countryData.countryCode = data.get("txtCountryCode");
        countryData.countryName = data.get("txtCountryName");
        countryData.callingCode = Number.parseInt(data.get("txtCallingCode"));
        countryData.officialLanguage = data.get("txtOfficialLanguage");
        countryData.capital = data.get("txtCapital");
        countryData.currency = data.get("txtCurrency");
        countryData.countryFlag = data.get("txtFlag");
        countryData.law = data.get("txtLaw");
        countryData.authority = data.get("txtAuthority");
        countryData.enforcement = data.get("txtEnforcement");
        countryData.penalties = data.get("txtPenalties");
        countryData.internetUsers = Number.parseInt(data.get("txtInternetUsers"));
        countryData.population = Number.parseInt(data.get("txtPopulation"));
        countryData.url = data.get("txtUrl");

        const postedData = await PostCountry(countryData);
        if (postedData) {
            alert("Country Data Saved Successfully");
            clearForm();
        }


        console.log("Posted Data: ", postedData);
    }

    const clearForm = () => {
        document.getElementById("txtCountryCode").value = "";
        document.getElementById("txtCountryName").value = "";
        document.getElementById("txtCallingCode").value = "";
        document.getElementById("txtOfficialLanguage").value = "";
        document.getElementById("txtCapital").value = "";
        document.getElementById("txtCurrency").value = "";
        document.getElementById("txtFlag").value = "";
        document.getElementById("txtLaw").value = "";
        document.getElementById("txtAuthority").value = "";
        document.getElementById("txtEnforcement").value = "";
        document.getElementById("txtPenalties").value = "";
        document.getElementById("txtInternetUsers").value = "";
        document.getElementById("txtPopulation").value = "";
        document.getElementById("txtUrl").value = "";
    }

    const GetCountryData = async (pageNumber = 0, pageSize = 10) => {
        await GetCountries(pageNumber, pageSize).then((data) => {
            let countryInfoList = [];
            setCountryData(data.content);
            data.content.forEach((countryInfo) => {

                let countryInfoArray = [];
                countryInfoArray.push(countryInfo.countryCode ? countryInfo.countryCode : "N/A");
                countryInfoArray.push(countryInfo.countryName ? countryInfo.countryName : "N/A");
                countryInfoArray.push(countryInfo.callingCode ? countryInfo.callingCode : "N/A");
                countryInfoArray.push(countryInfo.officialLanguage ? countryInfo.officialLanguage : "N/A");
                countryInfoArray.push(countryInfo.capital ? countryInfo.capital : "N/A");
                countryInfoArray.push(countryInfo.currency ? countryInfo.currency : "N/A");
                countryInfoArray.push(countryInfo.countryFlag ? countryInfo.countryFlag : "N/A");
                countryInfoArray.push(countryInfo.law ? countryInfo.law : "N/A");
                countryInfoArray.push(countryInfo.authority ? countryInfo.authority : "N/A");
                countryInfoArray.push(countryInfo.enforcement ? countryInfo.enforcement : "N/A");
                countryInfoArray.push(countryInfo.penalties ? countryInfo.penalties : "N/A");
                countryInfoArray.push(countryInfo.internetUsers ? countryInfo.internetUsers : 0);
                countryInfoArray.push(countryInfo.population ? countryInfo.population : 0);
                countryInfoArray.push(countryInfo.url ? countryInfo.url : "N/A");
                countryInfoArray.push(countryInfo.id);


                countryInfoList.push(countryInfoArray);
                console.log("Country Info Data: ", countryInfoArray);
            });

            setCountryInfoData(countryInfoList);
            // console.log("Country Info Data: ", countryInfoData);

            const pageableArray = [];

            pageableArray.push({
                totalPages: data.totalPages,
                currentPage: data.number,
                totalElements: data.totalElements
            
            });

            setPageData(pageableArray);

            console.log("Category Info Data: ", pageData);
        });

    }

    const handlePagination = (data) => {
        GetCountryData(data.selected, 10);

        console.log(data);
    }

    const findCountry = (data) => {
        return countrySavedData.find((category) =>
            category.id === data);
    }

    const handleCountryEdit = (id) => {
        editCountry(true);
        editPrimaryId(id);

        const filteredData = findCountry(id);

        console.log(`Filtered data ${filteredData}`);
        
        // set data for values
        setCountryCode(filteredData.countryCode);
        setCountryName(filteredData.countryName);
        setCallingCode(filteredData.callingCode);
        setOfficialLanguage(filteredData.officialLanguage);
        setCapital(filteredData.capital);
        setCurrency(filteredData.currency);
        setCountryFlag(filteredData.countryFlag);
        setLaw(filteredData.law);
        setAuthority(filteredData.authority);
        setEnforcement(filteredData.enforcement);
        setPenalties(filteredData.penalties);
        setInternetUsers(filteredData.internetUsers);
        setPopulation(filteredData.population);
        setUrl(filteredData.url);

        
    }

    const handleCountryDelete = (data) => {
        DeleteCountry(data);

        console.log(data);
    }

    return (
        <>
            <div className='countryForm' >
                <h4>Setup Country</h4>
                {/* Country Form */}
                <form ref={form} onSubmit={countrySubmit}>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtCountryCode"} labelName={"Country Code"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtCountryCode"} elemType={"text"} elemName={"txtCountryCode"} defaultValue={countryCode}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtCountryName"} labelName={" Country Name"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtCountryName"} elemType={"text"} elemName={"txtCountryName"} defaultValue={countryName}/>
                        </div>
                        
                    </div>

                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtCallingCode"} labelName={"Calling Code"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtCallingCode"} elemType={"text"} elemName={"txtCallingCode"} defaultValue={callingCode}/>
                        </div>
                        
                    </div>

                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtOfficialLanguage"} labelName={"Official Language"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtOfficialLanguage"} elemType={"text"} elemName={"txtOfficialLanguage"} defaultValue={officialLanguage}/>
                        </div>
                        
                    </div>

                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtCapital"} labelName={"Capital"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtCapital"} elemType={"text"} elemName={"txtCapital"} defaultValue={capital}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtCurrency"} labelName={"Currency"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtCurrency"} elemType={"text"} elemName={"txtCurrency"} defaultValue={currency}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtFlag"} labelName={"Country Flag"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtFlag"} elemType={"text"} elemName={"txtFlag"} defaultValue={flag}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtLaw"} labelName={"Country Law"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtLaw"} elemType={"text"} elemName={"txtLaw"} defaultValue={law}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtAuthority"} labelName={"Country Authority"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtAuthority"} elemType={"text"} elemName={"txtAuthority"} defaultValue={authority}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtEnforcement"} labelName={"Country Enforcement"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtEnforcement"} elemType={"text"} elemName={"txtEnforcement"} defaultValue={enforcement}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtPenalties"} labelName={"Country Penalties"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtPenalties"} elemType={"text"} elemName={"txtPenalties"} defaultValue={penalties}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtInternetUsers"} labelName={"Internet Users"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtInternetUsers"} elemType={"number"} elemName={"txtInternetUsers"} 
                            elemMin={"100000"} elemMax={"100000000000000"} defaultValue={internetUsers}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtPopulation"} labelName={"Population"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtPopulation"} elemType={"number"} elemName={"txtPopulation"} defaultValue={population}/>
                        </div>
                        
                    </div>
                    <div className="formElemnt">
                        <div>
                            <Label labelId={"txtUrl"} labelName={"Url"}/>
                        </div>
                        <div>
                            <TextInputs elemId={"txtUrl"} elemType={"text"} elemName={"txtUrl"} defaultValue={url} elemDisabled={true}/>
                        </div>
                        
                    </div>

                    <div className="formSuffix" >
                        <ButtonInput elemId={"btnSaveCountry"} elemClass={!editData ? "btnSuccess" : "btnEdit"} elemValue={!editData ? "Save Category" : "Update Category"} elemType={"submit"}/>
                    </div>

                    <div className="categoryTable">
                        <Table tableHeader={tableHeader} tableData={countryInfoData} 
                        pageDetails={pageData} handlePageChange={handlePagination} handleEdit={handleCountryEdit} handleDelete={handleCountryDelete}/>
                        
                    </div>
                </form>
            </div>
        </>
            );
}