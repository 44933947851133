import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'

const CountrySearch = () => {
    return (
        <div>
            <Navbar />
            <h2>CountrySearch</h2>
        </div>
    )
}

export default CountrySearch