import React from "react";
import ReactCountryFlag from "react-country-flag";


function CountryFlagDisplay({countryId}) {
    return (
      <div>
        <ReactCountryFlag countryCode={countryId} style={{ fontSize: "2em" }} svg={true} />
      </div>
    );
  }
  
  export default CountryFlagDisplay;