import { ExceptionService } from "../ExceptionService";

const continentAnalysisApi = "/continent-analysis"

export const GetContinentAnalysis =  async (legislatureTypeString) => {
    try {
        const response = await fetch(`/api${continentAnalysisApi}/percentage/${legislatureTypeString}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        const messageDetails = {
            msgTitle: 'API Error',
            msgType: 'exceptionError',
            msgContent: `There was a problem with the fetch operation: ${error.status}`
        }     
        ExceptionService(messageDetails)
        // alert('There was a problem with the fetch operation: ', error.message);
        console.error('There was a problem with the fetch operation: ', error);
    }
}