import React from 'react'
import "./MidsectionHeader.css"

const MidsectionHeader = () => {
    return (
        <div className='midsectionHeader'>
            <h2>Data Protection and Privacy Laws Across Africa</h2>
        </div>
    )
}

export default MidsectionHeader