import React from 'react'
import './Navbar.css'
import { IoMdDownload } from "react-icons/io";
import logo from '../../assets/snscript.svg'

import doc from '../../assets/IGNITE Cohort II Program.pdf'
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <div className='navbar'>
            <div>
                <a href='/'><img src={logo} alt='' className='logo' /></a>
            </div>

            <ul className='navlinks'>
                <li className='navlink'><Link to='/about'>About</Link></li>
                <li className='navlink'><Link to='/more'>IGNITE Program</Link></li>
            </ul>
            <div className='searchIcon'>
                <div>
                    <a href={doc} download='doc-document' target='_blank' rel='noreferrer'>Download Full Data</a>
                </div>
                <div className="download_icon">
                    <IoMdDownload />
                </div>
            </div>
        </div>
    )
}

export default Navbar