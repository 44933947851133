import React, { useEffect, useRef, useState } from "react";
import './SetupForm.css';
import { Label } from "../../Components/FormElements/Label/Label";
import { TextInputs } from "../../Components/FormElements/Inputs/TextInputs/TextInputs";
import { ButtonInput } from "../../Components/FormElements/Inputs/ButtonInputs/ButtonInputs";
import { GetLegislatureType, PostLegislatureType } from "../../services/LegislatureTypeService";
import { SelectInputs } from "../../Components/FormElements/Inputs/SelectInputs/SelectInput";
import { PostCountryLegislation } from "../../services/CountryLegislationService";
import { GetCountries } from "../../services/CountryServices";

export function SetupForm() {
    const legislatureTypeForm = useRef(null);
    const legislatureProtectionForm = useRef(null);    
    const countryLegisForm = useRef(null);

    const [countryData, setCountries] = useState([]);
    const [legislationTypeData, setLegislations] = useState([]);
    const [legislationProtectionData, setLegislationProtections] = useState([]);

    useEffect (() => {
        GetSelectData();

    }, []) ;

    const GetSelectData = async () => {
        await GetCountries(0, 100000).then((data) => {
            let starndardArray = [];
            data.content.forEach((country) => {
                let standardData = {
                    id: 0,
                    value: ""
                };
                standardData.id = country.id;
                standardData.value = country.countryName;
                starndardArray.push(standardData);
                
            });
            setCountries(starndardArray);
        });
        
        
        
        await GetLegislatureType(0, 100000).then((data) => {
            
            let starndardArray = [];
            
            data.content.forEach((type) => {
                let standardData = {
                    id: 0,
                    value: ""
                };
                standardData.id = type.id;
                standardData.value = type.legislatureType;
                starndardArray.push(standardData);
                
            });
            setLegislations(starndardArray);
        });
    }

    let legislatureTypeData = {
        id: 0,
        legislatureType: ""
    }
       

    const legTypeSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(legislatureTypeForm.current);
        let finalData;

        legislatureTypeData.legislatureType = data.get("txtLegislatureType");
        

        const postedData = await PostLegislatureType(legislatureTypeData);
        if (postedData) {
            alert("Legislature Type Saved Successfully");
            clearlegTypeForm();
            GetSelectData();
        }


        console.log("Posted Data: ", postedData);
    }    

    const countryLegisSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(countryLegisForm.current);
        let finalData;

        let countryLegisData = {
            id: null,
            countryId: 0,
            legislatureName: "",
            legislatureTypeId: 0,
            legislatureProtectionId: 0
            
        }

        countryLegisData.legislatureName = data.get("txtCountryLegisName");
        countryLegisData.countryId = Number.parseInt(data.get("selCountry"));
        countryLegisData.legislatureTypeId = Number.parseInt(data.get("selLegisType"));
        countryLegisData.legislatureProtectionId = Number.parseInt(data.get("selLegisProtection"));
        

        const postedData = await PostCountryLegislation(countryLegisData);
        if (postedData) {
            alert("Category Saved Successfully");
            clearCountryLegislationForm();
        }


        console.log("Posted Data: ", postedData);
    }

    const clearlegTypeForm = () => {
        document.getElementById("txtLegislatureType").value = "";
    }

    const clearCountryLegislationForm = () => {
        document.getElementById("txtCountryLegisName").value = "";
        document.getElementById("selCountry").value = "";
        document.getElementById("selLegisType").value = "";
    }

    return (
        <>
            
            <div id="setupForm">
                <div className="legislatureRow">
                <h4>Setup Legislature</h4>
                    <div id="legislatureType">
                        <form ref={legislatureTypeForm} onSubmit={legTypeSubmit}>
                            <div>
                                <Label labelId={"txtLegislatureType"} labelName={"Legislature Type"}/>
                            </div>
                            <div>
                                <TextInputs elemType={"text"} elemName={"txtLegislatureType"} elemId={"txtLegislatureType"}/> 
                            </div>
                            <div className="formSuffix" >
                                <ButtonInput elemId={"btnSaveLegType"} elemClass={"btnSuccess"} elemValue={"Save Legislature Type"} elemType={"submit"}/>
                            </div>
                        </form>
                    </div>
                    
                </div>
                {/* COuntry LEgislation Setup */}

                <div className="countryLegislatureRow">
                    <h4>Setup Legislature Type</h4>
                        <div>
                            <form ref={countryLegisForm} onSubmit={countryLegisSubmit}>
                                <div id="countryLegisName">
                                    <div>
                                        <Label labelId={"txtCountryLegisName"} labelName={"Legislation Name"}/>
                                    </div>
                                    <div>
                                        <TextInputs elemType={"text"} elemName={"txtCountryLegisName"} elemId={"txtCountryLegisName"}/> 
                                    </div>
                                </div>
                                
                                <div id="countrySelect">
                                    <div>
                                        <Label labelId={"selCountry"} labelName={"Country"}/>
                                    </div>
                                    <div>
                                        <SelectInputs elemName={"selCountry"} elemId={"selCountry"} optionData={countryData} /> 
                                    </div>

                                </div>
                                <div id="legisTypeSelect">
                                    <div>
                                        <Label labelId={"selLegisType"} labelName={"Legislature Type"}/>
                                    </div>
                                    <div>
                                        <SelectInputs elemName={"selLegisType"} elemId={"selLegisType"} optionData={legislationTypeData} /> 
                                    </div>

                                </div>
                                <div className="formSuffix" >
                                    <ButtonInput elemId={"btnSaveCountryLegislation"} elemClass={"btnSuccess"} elemValue={"Save Country Legislation"} elemType={"submit"}/>
                                </div>
                                
                            </form>
                        </div>
                        
                </div>
            </div>
        </>
    );
} 
