import { ButtonInput } from '../FormElements/Inputs/ButtonInputs/ButtonInputs';
import { TextInputs } from '../FormElements/Inputs/TextInputs/TextInputs';
import { Label } from '../FormElements/Label/Label';
import './CategoryListingSetup.css';
import * as ioIcons from "react-icons/io5";
import * as giIcons from "react-icons/gi";
import * as fiIcons from "react-icons/fi";
import * as faIcons from "react-icons/fa";
import * as riIcons from "react-icons/ri";
import * as biIcons from "react-icons/bi";
import * as tbIcons from "react-icons/tb";
import * as mdIcons from "react-icons/md";
import * as tiIcons from "react-icons/ti";
import { Suspense, useEffect, useRef, useState } from 'react';
import { DeleteCategory, GetCategories, GetCategory, PostCategory } from '../../services/CategoryServices';
import { SelectInputs } from '../FormElements/Inputs/SelectInputs/SelectInput';
import { Table } from '../Table/Table';
import Navbar from '../Navbar/Navbar';
import CarouselPage from '../Carousel/Carousel';

export function CategoryListingSetup() {
    const [iconList, setIconList] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [categoryInfoData, setCategoryInfoData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [editData, editCategory] = useState(false);
    const [editId, editPrimaryId] = useState(0);
    const [categoryName, setCategoryName] = useState("");

    const categoryForm = useRef(null);

    let collection = [];
    let selectedDiv = "";
    let prevSelectedDiv = "";

    useEffect(() => {
        createIconListing();
        GetCategoryData(0, 10);
    }, []);

    const createIconListing = () => {

        allIconListing(Object.keys(ioIcons));
        // allIconListing(Object.keys(giIcons));
        // allIconListing(Object.keys(fiIcons));
        // allIconListing(Object.keys(faIcons));
        // allIconListing(Object.keys(riIcons));
        // allIconListing(Object.keys(biIcons));
        // allIconListing(Object.keys(tbIcons));
        allIconListing(Object.keys(mdIcons));
        // allIconListing(Object.keys(tiIcons));

        

    }

    const allIconListing = (icons) => {
        let iconSize = iconList.length === 0 ? 0 : iconList.length;
        icons.forEach((icon) => {
            let standardData = {
                id: 0,
                value: ""
            };
            standardData.id = iconSize;
            standardData.value = icon;
            collection.push(standardData);
            iconSize++;
        });

        setIconList(collection);
    }

    const handleDivClick = (e) => {
        selectedDiv = e.target.outerText;

        changeIconDivColor(selectedDiv);

        
    }

    const changeIconDivColor = (id) => {
        const div = document.getElementById(id);
        div.style.backgroundColor = "lightblue";

        if (prevSelectedDiv !== "") {
            const prevDiv = document.getElementById(prevSelectedDiv);
            prevDiv.style.backgroundColor = 'rgba(61, 61, 61, 0.733)';
        }
        prevSelectedDiv = selectedDiv;


        console.log("Selected Div: ", selectedDiv);
    }


    const categorySubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(categoryForm.current);
        let finalData;

        let categoryData = {
            id: 0,
            name: "",
            icon: ""
        }

        if (editData) {
            categoryData.id = editId;
        }
        categoryData.name = data.get("txtCategoryName");
        categoryData.icon = selectedDiv;
        

        const postedData = await PostCategory(categoryData);
        if (postedData) {
            alert("Category Saved Successfully");
            clearCategoryForm();
            GetCategoryData(0, 10);
        }


        console.log("Posted Data: ", postedData);
    }

    const GetCategoryData = async (page, size) => {

        await GetCategories(page, 10).then((data) => {
            let categoryInfoList = [];
            setCategoryData(data.content);
            data.content.forEach((categoryInfo) => {

                

                let categoryInfoArray = [];
                categoryInfoArray.push(categoryInfo.name);
                categoryInfoArray.push(categoryInfo.icon);
                categoryInfoArray.push(categoryInfo.id);

                categoryInfoList.push(categoryInfoArray);
            });

            setCategoryInfoData(categoryInfoList);

            const pageableArray = [];

            pageableArray.push({
                totalPages: data.totalPages,
                currentPage: data.number,
                totalElements: data.totalElements
            
            });

            setPageData(pageableArray);

            console.log("Category Info Data: ", pageData);
        });
    }

    const handlePagination = (data) => {
        GetCategoryData(data.selected, 10);

        console.log(data);
    }

    const findCategory = (data) => {
        return categoryData.find((category) =>
            category.id === data);
    }

    const handleCategoryEdit = (id) => {
        editCategory(true);
        editPrimaryId(id);

        const filteredData = findCategory(id);

        console.log(`Filtered data ${filteredData}`);
        
        
        setCategoryName(filteredData.name);
        selectedDiv = filteredData.icon;

        changeIconDivColor(selectedDiv);

        console.log("Selected Name: ", categoryName);
    }

    const handleCategoryDelete = (data) => {
        DeleteCategory(data);

        console.log(data);
    }


    const clearCategoryForm = () => {
        document.getElementById("txtCategoryName").value = "";
        selectedDiv = "";
    }

    let Icon ; 

    return (
        <>
        <   Navbar />
            <CarouselPage />
            <div className="categoryRow">
                    <h4>Setup Category</h4>
                        <div>
                            <form ref={categoryForm} onSubmit={categorySubmit}>
                                <div id="categoryName">
                                    <div>
                                        <Label labelId={"txtCategoryName"} labelName={"Category"}/>
                                    </div>
                                    <div>
                                        <TextInputs elemType={"text"} elemName={"txtCategoryName"} elemId={"txtCategoryName"} defaultValue={categoryName}/> 
                                    </div>
                                </div>
                                
                                <div id="categoryIcon">
                                    <div>
                                        <Label labelId={"txtCategoryIcon"} labelName={"Category Icon"}/>
                                    </div>
                                    <div className='iconListing'>
                                        {
                                            iconList.map((icon, index) => {
                                                Icon =  tiIcons[icon.value] ? tiIcons[icon.value] : mdIcons[icon.value]? mdIcons[icon.value] : 
                                                        tbIcons[icon.value] ? tbIcons[icon.value] : biIcons[icon.value] ? biIcons[icon.value] 
                                                        : riIcons[icon.value] ? riIcons[icon.value] : faIcons[icon.value] ? faIcons[icon.value] : 
                                                        fiIcons[icon.value] ? fiIcons[icon.value] : giIcons[icon.value] ? giIcons[icon.value] 
                                                        : ioIcons[icon.value] ? ioIcons[icon.value] : tiIcons.TiAnchor;
                                                return (
                                                    <div key={index} className="iconDiv">
                                                        <div className={selectedDiv == icon.value ? "iconValueSelected" : "iconValue"} 
                                                        onClick={handleDivClick} id={icon.value}>
                                                            <span>
                                                                <Suspense fallback={<></>}>
                                                                    <Icon className='icons'/>
                                                                    {icon.value}
                                                                </Suspense>
                                                            </span>
                                                        </div>
                                                        {/* <div className="icon">
                                                            {eval(`React.createElement(${icon.value})`)}
                                                        </div> */}
                                                    </div>
                                                )
                                            })
                                        }
                                        <div>

                                        </div>
                                    </div>

                                </div>
                                <div className="formSuffix" >
                                    <ButtonInput elemId={"btnSaveCategories"} elemClass={!editData ? "btnSuccess" : "btnEdit"} elemValue={!editData ? "Save Category" : "Update Category"}/>
                                </div>

                                <div className="categoryTable">
                                    <Table tableHeader={["Category", "Category Icon", "Actions"]} tableData={categoryInfoData} 
                                    pageDetails={pageData} handlePageChange={handlePagination} handleEdit={handleCategoryEdit} handleDelete={handleCategoryDelete}/>
                                    
                                </div>
                                
                            </form>
                        </div>
                        
                </div>
        </>
    );
}