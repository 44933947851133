import React, { Component } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import './Category.css'
import { GetCategoryInfoByCountryIdAndCategoryId } from '../../services/CategoryInfoServices'
import withRouter from '../../withRouter'
import { SelectInputs } from '../../Components/FormElements/Inputs/SelectInputs/SelectInput'
import { GetCountries } from '../../services/CountryServices'
import Partners from '../../Components/Partners/Partners'
import Banner from '../../Components/Banner/Banner'
import CountryFlagDisplay from '../../Components/CountryFlag/CountryFlagDisplay'
import { GetCategory } from '../../services/CategoryServices'

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country1: 1,
            country2: 0,
            categoryId: 0,
            categoryInfo1: "",
            image1: null,
            categoryInfo2: "",
            image2: null,
            countryData: [],
            loadData: false,
            country1Code: "",
            country2Code: "",
            country1Name: "",
            country2Name: "",
            categoryTitle:""
        };
        props.params.countryId ? this.state.country1 = props.params.countryId : this.state.country1 = 1;
        this.selectCountry1 = this.selectCountry1.bind(this);
        this.selectCountry2 = this.selectCountry2.bind(this);
    }

    componentDidMount() {
        if (!this.state.loadData) {
            // this.setState({categoryId: })
            this.CountryData();
            this.CategoryById();
            this.CategoryData1();
            this.setState({ loadData: true });


        }

        const height2 = document.getElementById('main2');
            console.log("Height 2: ", height2);

        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.params !== this.props.params) {
            this.CategoryData1();
            this.CategoryData2();
            this.CategoryById();
        }

        if (prevState.country2 !== this.state.country2 || prevState.country1 !== this.state.country1) {
            setTimeout(function(){
                const height1 = document.getElementById('main1');
                const height2 = document.getElementById('main2');
                console.log("Height 2: ", height2);

                if (height1.clientHeight > height2.clientHeight) {
                    console.log("Height 1: ", height1.clientHeight);
                    height2.style.height = height1.clientHeight + "px";
                } else {
                    height1.style.height = height2.clientHeight + "px";
                }
            }, 1000);
            

        }
    }

    selectCountry1(val) {
        console.log("Country 1: ", val);
        this.setState({
            country1: val
        }, () => {
            this.CategoryData1();
        });
    }

    selectCountry2(val) {
        console.log("Country 2: ", val);
        this.setState({
            country2: val
        }, () => {
            this.CategoryData2();
        });
        // if (val !== 0) {
        //     this.setState({ country2: val });
        //     this.CategoryData2();
        // }
    }
    

    async CountryData() {
        await GetCountries(0, 100000).then((data) => {
            let starndardArray = [];
            data.content.forEach((country) => {
                let standardData = {
                    id: 0,
                    value: ""
                };
                standardData.id = country.id;
                standardData.value = country.countryName;
                starndardArray.push(standardData);
                
            });
            this.setState({ countryData: starndardArray });
        });
    }

    async CategoryById() {
        await GetCategory(this.props.params.id)
            .then((data) => {
                this.setState({ categoryTitle: data.name })
            });
    }

    

    async CategoryData1() {
        await GetCategoryInfoByCountryIdAndCategoryId(this.state.country1, this.props.params.id)
            .then((data) => {
                if (data.length > 0) {
                    this.setState({ country1Code: data[0].country.countryCode })
                    this.setState({ country1Name: data[0].country.countryName })
                    this.setState({ categoryInfo1: data[0].categoryInformation })
                    this.setState({ image1: data[0].imageUrl  })
                } else {
                    this.setState({ image1: null})
                    this.setState({ categoryInfo1: "No Data!" })
                }

            });
        console.log("Category info: ", this.categoryInfo);
    }

    async CategoryData2() {
        await GetCategoryInfoByCountryIdAndCategoryId(this.state.country2, this.props.params.id)
            .then((data) => {
                if (data.length > 0) {
                    this.setState({ country2Code: data[0].country.countryCode })
                    this.setState({ country2Name: data[0].country.countryName })
                    this.setState({ categoryInfo2: data[0].categoryInformation })
                    this.setState({ image2: data[0].imageUrl })
                } else {
                    this.setState({ image2: null })
                    this.setState({ categoryInfo2: "No Data!" })
                }

            });
        console.log("Category info: ", this.categoryInfo);
    }

    createMarkup(text) { return { __html: text }; };

    render() {
        const {categoryInfo1, image1, categoryInfo2, image2, countryData } = this.state;
        return (

            <div className='container'>
                <Navbar />
                <div className='title'>
                    <h3>{this.state.categoryTitle.toUpperCase()}</h3>
                </div>
                <div className='body_container'>
                    <div class="navigation">
                        <Sidebar />
                    </div>
                    
                    <div className='content_body'>
                        <div className={!categoryInfo2 ?'comparisonContainer' : 'onComparisonContainer'} id='main1'>
                            <div className='comparisonHeader'>
                                
                                { !categoryInfo2 ?
                                    <>
                                        <div id="firstSelect">
                                            <div className='comparisonIcon'>
                                                {this.state.country1Code && <CountryFlagDisplay countryId={this.state.country1Code} />}
                                                <label style={{verticalAlign: "middle"}}>{this.state.country1Name}</label>
                                            </div>
                                            
                                            <div className='selectInputs'>
                                                <SelectInputs elemName={"selCountry1"} elemId={"selCountry1"} optionData={countryData} 
                                                optionsState={this.state.country1}
                                                sendValueToParent={this.selectCountry1} />
                                            </div>
                                        </div>
                                        <div id="secondSelect">
                                            <div className='comparisonIcon'>
                                                {this.state.country2Code && <CountryFlagDisplay countryId={this.state.country2Code} />}
                                                <label>Compare to:</label>
                                            </div>                                            
                                            <div className='selectInputs'>
                                                <SelectInputs elemName={"selCountry2"} elemId={"selCountry2"} optionData={countryData} 
                                                optionsState={this.state.country2}
                                                sendValueToParent={this.selectCountry2} />
                                            </div>
                                        </div>
                                    </> : 
                                    <div id="thirdSelect">
                                        <div className='comparisonIcon'>
                                            <CountryFlagDisplay countryId={this.state.country1Code} />
                                            <label>{this.state.country1Name}</label>
                                        </div>
                                        
                                        <div className='selectInputs'>
                                            <SelectInputs elemName={"selCountry1"} elemId={"selCountry1"} optionData={countryData} 
                                            optionsState={this.state.country1}
                                            sendValueToParent={this.selectCountry1} />
                                        </div>
                                    </div>
                                }
                                
                            </div>
                            <div className='comparisonBody'>
                                <div id="comparison1">
                                    { image1 && 
                                        <div className='comparisonImg'>
                                            <img src={"/"+image1} alt='' className='comp_img' width="100%" height="100%"/>
                                        </div>

                                    }
                                    <div className='comparisonData' dangerouslySetInnerHTML={this.createMarkup(categoryInfo1)} />
                                </div>
                            </div>
                        </div>


                        { categoryInfo2 && (
                        <div className='onComparisonContainer' id='main2'>
                            <div className='comparisonHeader'>
                                <div id="thirdSelect">
                                    <div className='comparisonIcon'>
                                        <CountryFlagDisplay countryId={this.state.country2Code} />
                                        <label>{this.state.country2Name}</label>
                                    </div>
                                    
                                    <div className='selectInputs'>
                                        <SelectInputs elemName={"selCountry2"} elemId={"selCountry2"} optionData={countryData} 
                                        optionsState={this.state.country2}
                                        sendValueToParent={this.selectCountry2} />
                                    </div>

                                </div>
                                
                            </div>
                            <div className='comparisonBody'>
                                <div id="comparison2">
                                    {
                                        image2 && 
                                        <div className='comparisonImg'>
                                            <img src={"/"+image2} alt='' className='comp_img' width="100%" height="100%"/>
                                        </div>
                                    }
                                    <div className='comparisonData' dangerouslySetInnerHTML={this.createMarkup(categoryInfo2)} />
                                </div>
                            </div>

                        </div>) }
                    </div>
                </div>
                <Partners />
                <Banner />
            </div>
        )
    }
}
export default withRouter(Category) 