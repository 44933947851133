import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'

const Analysis = () => {
    return (
        <div>
            <Navbar />
            <h2>
                Analysis
            </h2>
        </div>
    )
}

export default Analysis