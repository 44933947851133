import React, { useEffect } from 'react'
import './Infobar.css'
import { useSpring, animated } from 'react-spring'
import { GetContinentAnalysis } from '../../services/ContinentAnalysisServices';

function Number({ n }) {
    const { number } = useSpring({
        from: { number: 0 },
        number: n,
        delay: 200,
        config: { mass: 1, tension: 20, friction: 10 },
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const Infobar = () => {
    const [lawPercent, setLawPercent] = React.useState(0);
    const [draftPercent, setDraftPercent] = React.useState(0);
    const [noLawPercent, setNoLawPercent] = React.useState(0);
    const [noDataPercent, setNoDataPercent] = React.useState(0);

    useEffect(() => {
        LoadData();
        setInterval(() => {
            LoadData();
        }, 10000);
    }, []);

    const LoadData = () => {
        GetContinentAnalysisData("Law");
        GetContinentAnalysisData("Draft");
        GetContinentAnalysisData("No Law");
        GetContinentAnalysisData("No Data");
    }

    const GetContinentAnalysisData = async (legislatureTypeString) => {
        await GetContinentAnalysis(legislatureTypeString).then((response) => {
            
            if (response === undefined) {
                response = {percentageValue: 0};
            }

            switch(legislatureTypeString) {
                case "Law":
                    setLawPercent(response.percentageValue ? response.percentageValue : 0);
                  break;
                case "Draft":
                    setDraftPercent(response.percentageValue ? response.percentageValue : 0);
                  break;

                case "No Law":
                    setNoLawPercent(response.percentageValue ? response.percentageValue : 0);
                  break;
                
                case "No Data":
                    setNoDataPercent(response.percentageValue ? response.percentageValue : 0);
                    break;
                default:
                  // code block
            }
        });
    }


    return (
        <div className='infobar'>
            <div className='statItem'>
                <div className='statPer legistlation'><Number n={lawPercent} />%</div>
                <div className='statItem_body'>
                    <p>COUNTRIES WITH </p>
                    <p><span>LEGISTLATION</span></p>
                </div>
            </div>

            <div className='statItem'>
                <div className=' statPer draft'><Number n={draftPercent} />%</div>
                <div className='statItem_body'>
                    <p>COUNTRIES WITH </p>
                    <p><span>DRAFT LEGISTLATION</span></p>
                </div>
            </div>

            <div className='statItem'>
                <div className='statPer nolegistlation'><Number n={noLawPercent} />%</div>
                <div className='statItem_body'>
                    <p>COUNTRIES WITH </p>
                    <p><span>NO LEGISTLATION</span></p>
                </div>

            </div>
            <div className='statItem'>
                <div className='statPer nodata'><Number n={noDataPercent} />%</div>
                <div className='statItem_body'>
                    <p>COUNTRIES WITH </p>
                    <p><span>NO DATA</span></p>
                </div>

            </div>
        </div >
    )
}

export default Infobar