import { useEffect } from "react"
import { ExceptionService } from "../ExceptionService";

const countryApi = "/countries"

export const PostCountry =  async (countryData) => {
    try {
        const response = await fetch("/api" + countryApi, {
            method: 'POST',
            body: JSON.stringify(countryData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        const messageDetails = {
            msgTitle: 'API Error',
            msgType: 'exceptionError',
            msgContent: `There was a problem with the fetch operation: ${error.status}`
        }     
        ExceptionService(messageDetails)
        // alert('There was a problem with the fetch operation: ', error.message);
        console.error('There was a problem with the fetch operation: ', error);
    }
}

export const GetCountries =  async (pageNumber = 0, pageSize = 10) => {
    try {
        const response = await fetch(`/api${countryApi}?page=${pageNumber}&size=${pageSize}&sort=countryName&countryName.dir=asc`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        const messageDetails = {
            msgTitle: 'API Error',
            msgType: 'exceptionError',
            msgContent: `There was a problem with the fetch operation: ${error.status}`
        }     
        ExceptionService(messageDetails)
        // alert('There was a problem with the fetch operation: ', error.message);
        console.error('There was a problem with the fetch operation: ', error);
    }
}

export const DeleteCountry=  async (id) => {
    try {
        const response = await fetch(`/api${countryApi}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

    } catch (error) {
        const messageDetails = {
            msgTitle: 'API Error',
            msgType: 'exceptionError',
            msgContent: `There was a problem with the fetch operation: ${error.status}`
        }     
        ExceptionService(messageDetails)
        // alert('There was a problem with the fetch operation: ', error.message);
        console.error('There was a problem with the fetch operation: ', error);
    }
}