import { useState } from "react";
import { ExceptionComponent } from "./Components/Exception/ExceptionComponent";
let message = {};
export const ExceptionService = (msgDetail) => {
    console.log('msgDetail', msgDetail);

    message = msgDetail;
    
    
}


// const recieveMessage = (messageDetails) => {
//     setMessages([...messages, messageDetails]);
// }

export const displayMessage = () => {
    clearServiceMessages();
    return message;
}

const clearServiceMessages = () => {
    if (message) {
        setTimeout(() => {
            message = {};
        }, 5000);
    }
    
    
}